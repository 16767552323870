import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  {
    path: 'category/auto',
    loadChildren: () => import('./categories/auto/auto.module').then( m => m.AutoPageModule)
  },
  {
    path: 'category/beauty',
    loadChildren: () => import('./categories/beauty/beauty.module').then( m => m.BeautyPageModule)
  },
  {
    path: 'category/restaurant',
    loadChildren: () => import('./categories/restaurant/restaurant.module').then( m => m.RestaurantPageModule)
  },
  {
    path: 'category/freetime',
    loadChildren: () => import('./categories/freetime/freetime.module').then( m => m.FreetimePageModule)
  },
  {
    path: 'category/health',
    loadChildren: () => import('./categories/health/health.module').then( m => m.HealthPageModule)
  },
  {
    path: 'category/house',
    loadChildren: () => import('./categories/house/house.module').then( m => m.HousePageModule)
  },
  {
    path: 'category/culture',
    loadChildren: () => import('./categories/culture/culture.module').then( m => m.CulturePageModule)
  },
  {
    path: 'category/accessories',
    loadChildren: () => import('./categories/accessories/accessories.module').then( m => m.AccessoriesPageModule)
  }






];

@NgModule({
  entryComponents: [ ],
  imports: [
    RouterModule.forRoot(routes, { useHash: true ,preloadingStrategy: PreloadAllModules }),
  ],

  exports: [RouterModule]
})
export class AppRoutingModule {}
