import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

  props: any;

  constructor(public modalCtrl: ModalController,navParams: NavParams) {
    // componentProps can also be accessed at construction time using NavParams
    this.props = navParams.get('props');
  }

  async dismissModal() {
    await this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
